<template lang="pug">
.wrapper(v-if="!isActivAccessibility")
    .row.justify-between.q-mt-lg.q-mb-md
        router-link.font-24.line-height-32.text-weight-medium.text-primary.decoration-no(
            to="articles"
            title="Статьи" itemprop="text") Статьи
        router-link.font-16.text-weight-bold.text-positive.line-height-24(:to="city ? `/${city?.slug}/articles` : '/articles'" v-if="articles?.results?.length > 4" title="Все статьи")
            | Все статьи  
    .row(:class="articles?.results?.length > 4 ? 'justify-between' : ''")
        router-link.link.column.text-primary.article(
            v-for="(item, index) in articles?.results"
            :style="articles?.results?.length > 4 ? '' : 'margin-right: 36px;'"
            :to="city ? `/${city?.slug}/article/${item.slug}` : `article/${item.slug}`"
            :title="`${item.slug}`")
            template(v-if="index < 4")
                q-img.article__img(:src="item.imagePreviewCdn" no-spinner)
                .font-16.line-height-24.text-weight-bold.q-mt-md.article__name(
                    style="overflow: hidden;"
                )
                    | {{ item.title }}
                .line-height-20.q-mt-sm.article__title
                    | {{ item.previewText }}
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'ArticlesDesktop',
    props: {
        articles: {
            type: Object,
            default: () => null,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);
        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        const showFull = ref(false);

        const goTo = (link) => {
            router.push(`/article/${link}`);
        };
    
        return {
            goTo,
            showFull,
            city,
            isActivAccessibility,
        };
    },
};
</script>

<style lang="scss" scoped>
.article {
    width: 300px;

    &__img {
        height: 200px;
        border-radius: 16px;
    }

    &__title {
        height: 60px;
        overflow: hidden;
    }
}

</style>
