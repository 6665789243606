<template lang="pug">
.page-top-area.fixed-top
.index-page
    .fixed
        header-mobile
        city-plash-mobile
        city-form-mobile(v-if="!userAuthorized" :menu="false" indexPage)
        .row.q-mx-md.q-pb-sm.q-mt-sm#address(v-else @click="showAddressModal = !showAddressModal")
            q-icon(
                size="20px"
                name="etabl:location-marker"
                color="positive").col-1
            .col-11
                .line-height-medium.q-ml-sm.text-primary(v-if="!userAddress")
                    | {{ headerCity }}
                .line-height-medium.q-ml-sm.text-primary(v-else)
                    | {{ userAddress.address_representation }}
        mobile-cards
    .margin-top
        fast-links-mobile
        carousel-mobile(
            v-show="advertismentItemsTop?.length"
            :slides="advertismentItemsTop"
            :topSlider="true")
        product-slider-mobile(
            v-if="discount?.length"
            label="Предложения по лучшим ценам"
            :slides="discount")
        carousel-mobile(v-if="advertismentItemsBottom.length"
            v-show="advertismentItemsBottom?.length"
            label="Акции"
            hrefTo="marketing-list"
            :slides="advertismentItemsBottom")
    .q-pl-md
        .font-16.text-primary.text-weight-medium
          | Почему выбирают онлайн-аптеку {{ link }}?
        CarouselAdvantages(
          :slides="reasons"
        )
    p.text-primary.font-20.text-weight-medium.q-pl-lg(v-if='season?.products.length' )
        | Сезонные товары
    product-slider-mobile(
        v-if='season?.products.length'
        :slides="season.products"
        :standartPreview="false"
        )
    articles-mobile(
                v-if="articles"
                :articles="articles"
            )
            product-slider-mobile(
                v-if="userAuthorized && history?.length"
                label="Вы недавно просматривали"
                :slides="history")
    //- Alphabet
.address-card.b-radius-16.bg-secondary(v-if="!userAddress && userAuthorized && !adressDismissed")
    .q-px-md.q-pt-md.q-pb-sm
        city-form-mobile(:menu="false" indexCard)
    .b-radius-8.bg-grey-5.q-mt-md.q-px-md.q-py-sm.text-primary
        .text-weight-bold.line-height-16.font-12
            | Добавить пункт выдачи или адрес доставки?  
        .q-mt-xs.line-height-16.font-12
            | Это поможет вам заранее увидеть условия доставки товаров
        .row.q-mt-sm.justify-between
            base-btn(
                width="120"
                height="30"
                bradius="16"
                font_size="13"
                outline
                color="secondary"
                text_color="positive"
                label="Не сейчас"
                border="1px solid #0ca551"
                @click="dismissAddress"
            )
            base-btn(
                width="120"
                height="30"
                label="Добавить"
                bradius="16"
                font_size="13"
                @click="showDeliveryAddress"
            )
app-plash(v-show="!appCordova")
app-update-popup(v-if="app && needUpdate")
q-dialog(
    v-model="showAddressModal"
    @hide="showAddressModal = false"
    full-width
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down")
    delivery-address-choose(
        addressHeader
        :showAddressChoose="showAddressModal"
        :userAddress="userAddress"
        @hide="showAddressModal = false"
        @dropAddress="dropAddress"
        @close="closeAddressChoose"
        @showMap="showMapDialog"
    )
q-dialog.modal-map(
    v-model="mapDialog" 
    full-width
    full-height
    transition-show="slide-left"
    transition-hide="slide-right"
    :maximized="true"
    @hide="closeMapDialog")
        .full-width.full-height.bg-secondary.b-radius-0.relative-position
            base-mobile-map(
                addressChoose
                :basket="false"
                :drugstores="drugstores"
                @hide="mapDialog = false")
        
</template>

<script>
import { useQuasar, LocalStorage } from 'quasar';
import { computed, ref, onMounted, defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Advantages from 'components/Vacancy/Advantages.vue';
import CarouselAdvantages from 'components/Carousel/CarouselAdvantag.vue';
import Alphabet from 'components/Alphabet/Alphabet.vue';
import ProductSliderDesktop from 'components/ProductSlider/ProductSliderDesktop.vue';


const FastLinksMobile = defineAsyncComponent(() => import('components/FastLinks/FastLinksMobile.vue'));
const CarouselMobile = defineAsyncComponent(() => import('components/Carousel/CarouselMobile.vue'));
const ProductSliderMobile = defineAsyncComponent(() => import('components/ProductSlider/ProductSliderMobile.vue'));
const ArticlesMobile = defineAsyncComponent(() => import('components/Articles/ArticlesMobile.vue'));
const CityPlashMobile = defineAsyncComponent(() => import('components/CityPlash/CityPlashMobile.vue'));
const HeaderMobile = defineAsyncComponent(() => import('components/Header/HeaderMobile.vue'));
const AppUpdatePopup = defineAsyncComponent(() => import('components/AppUpdatePopup/AppUpdatePopup.vue'));
const AppPlash = defineAsyncComponent(() => import('components/AppPlash/AppPlash.vue'));
const MobileCards = defineAsyncComponent(() => import('src/components/MobileCards/MobileCards.vue'));
const CityFormMobile = defineAsyncComponent(() => import('components/CityForm/CityFormMobile.vue'));
const DeliveryAddressChoose = defineAsyncComponent(() => import('components/BasketNew/DeliveryAddressChoose.vue'));
const BaseMobileMap = defineAsyncComponent(() => import('components/Base/BaseMobileMap.vue'));
const BaseBtn = defineAsyncComponent(() => import('components/Base/BaseBtn.vue'));


export default {
    name: 'HomeMobile',
    components: {
        ProductSliderDesktop,
        Alphabet,
        CarouselAdvantages,
        Advantages,
        AppPlash,
        MobileCards,
        HeaderMobile,
        AppUpdatePopup,
        CarouselMobile,
        CityPlashMobile,
        ProductSliderMobile,
        ArticlesMobile,
        FastLinksMobile,
        CityFormMobile,
        DeliveryAddressChoose,
        BaseMobileMap,
        BaseBtn,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const q = useQuasar();

        const ceoFirst = ref(false);
        const ceoSecond = ref(false);
        const ceoThrid = ref(false);
        const ceoFour = ref(false);
        const needUpdate = ref(false);
        const showAddressModal = ref(false);
        const mapDialog = ref(false);
        const drugstores = ref([]);
        const adressDismissed = ref(true);
        const addresHeight = ref('50px');
        const appCordova = ref('false');

        const discount = computed(() => store.getters['advertisement/DISCOUNT']);
        const advertisment = computed(() => store.getters['advertisement/ADVERTISMENT']);
        const history = computed(() => store.getters['advertisement/HISTORY']);
        const articles = computed(() => store.getters['advertisement/ARTICLES']);
        const season = computed(() => store.getters['advertisement/SEASONS']);

        const platform = computed(() => store.getters['device/PLATFORM']);

        const google = computed(() => platform.value.userAgent.includes('Chrome-Lighthouse') || platform.value.userAgent.includes('Google Page Speed Insights'));

        // авторизован ли пользователь
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);

        const advertismentItemsTop = computed(() => {
            return (advertisment.value?.result || [])
                .filter((item) => {
                    return item.viewLocation === 'top'
                        && item.image;
                });
        });

        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);

        const advertismentItemsBottom = computed(() => {
            const result = (advertisment.value?.result || [])
                .filter((item) => {
                    return item.viewLocation === 'bottom'
                        && item.image;
                });

            return result.length ? result : [];
        });

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const headerCity = computed(() => city.value?.name || 'Белгород');

        if (process.env.CLIENT) {
            document.addEventListener('deviceready', () => {
                StatusBar.styleDefault();

                // window.open = cordova.InAppBrowser.open;
                window.screen.orientation.lock('portrait');

                try {
                    AppUpdate.needsUpdate(function (appUpdateObj) {
                        console.log(appUpdateObj.update_available, window?.cordova?.platformId);

                        if(appUpdateObj.update_available == 1) {
                            needUpdate.value = true;
                        }

                        if (window?.cordova?.platformId == 'ios' && appUpdateObj.update_available == 'true') {
                            needUpdate.value = true;
                        }
                    });
                } catch {}


                universalLinks.subscribe('payment', function ({ path, params }) {
                    router.push({
                        path,
                        query: {
                            ...params,
                        },
                    });

                    store.dispatch('user/USER_GET_COUNT_ACTIVE_ORDERS');
                });
            }, false);
        }

        if (process.env.CLIENT) {
            // StatusBar.backgroundColorByHexString('#fbfafa');

            if (window?.cordova?.platformId == 'android') {
                StatusBar.backgroundColorByHexString('#fbfafa');
                StatusBar.overlaysWebView(false);
                StatusBar.styleDefault();
            }
        }

        const userAddress = computed(() => store.getters['user/USER_ADDRESS']);

        const dropAddress = async () => {
            await store.dispatch('user/UPDATE_USER_DATA', {
                address: {
                    address_id: null,
                },
            });

            if (process.env.CLIENT)
                window.location.reload(true);
        };

        const closeAddressChoose = async (value) => {

            await store.dispatch('user/UPDATE_USER_DATA', {
                address: {
                    address_id: value.id,
                    is_delivery_address: !value.isPickup,
                },
            });

            if (process.env.CLIENT)
                window.location.reload(true);

            deliveryAddressDialog.value = false;
        };

        const showMapDialog = async () => {
            drugstores.value = await store.dispatch('drugstores/GET_ALL_DRUGSTORES');
            drugstores.value = drugstores.value.filter((item) => !item.isParcelAutomat);

            // openForm.value = true;
            mapDialog.value = true;
        };

        const dismissAddress = () => {
            adressDismissed.value = true;
            LocalStorage.set('hidePlash', true);
        };

        const showDeliveryAddress = () => {
            dismissAddress();
            showAddressModal.value = true;
        };

        const checkPlashHidden = () => {
            if(LocalStorage.getItem('hidePlash') !== true) adressDismissed.value = false;
        };

        const link = `${process.env.APP_SERVICE_HOSTNAME}`;

        const reasons = ref([
            {
                icon_top: 'reasons-1',
                title_top: 'Широкий ассортимент 50 000 товаров',
                subtitle_top: 'В наших аптеках и под заказ на сайте',
                icon_bottom: '1_bot',
                title_bottom: 'Программа лояльности скидки до 20%',
                subtitle_bottom: 'Гибкая и выгодная программа для клиентов',
            }, {
                icon_top: 'reasons-2',
                title_top: 'Гарантия качества',
                subtitle_top: 'Оригинальные лекарства и товары надлежащего качества',
                icon_bottom: '2_bot',
                title_bottom: 'Бронирование заказов круглосуточно',
                subtitle_bottom: 'Бронируйте заказ на сайте или по телефону ',
            }, {
                icon_top: 'reasons-3',
                title_top: 'Доставка курьером на дом и в офис',
                subtitle_top: ' Заказывайте на сайте или по телефону ',
                icon_bottom: '3_bot',
                title_bottom: 'Консультация квалифицированного специалиста',
                subtitle_bottom: 'Качественный сервис',
            },
        ]);

        onMounted(() => {
            if(userAddress.value) {
                const el = document.getElementById('address');

                addresHeight.value = `${el.getBoundingClientRect().height}px`;
            }

            setTimeout(() => {
                checkPlashHidden();
            }, 100);

            if(q.platform.is.cordova) {
                appCordova.value = true;
            };
        });

        return {
            appCordova,
            ceoFirst,
            ceoSecond,
            ceoThrid,
            ceoFour,
            route,
            discount,
            advertisment,
            advertismentItemsTop,
            advertismentItemsBottom,
            needUpdate,
            history,
            userAuthorized,
            userCityId,
            articles,
            city,
            headerCity,
            showAddressModal,
            dropAddress,
            closeAddressChoose,
            userAddress,
            mapDialog,
            showMapDialog,
            drugstores,
            dismissAddress,
            adressDismissed,
            showDeliveryAddress,
            addresHeight,
            checkPlashHidden,
            link,
            reasons,
            season,
        };
    },
};
</script>
<style lang="scss" scoped>
.index-page {
    padding-bottom: calc(50px + constant(safe-area-inset-bottom));
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
}

:deep(.q-page.mobile) {
    min-height: 100px !important;
}

.carousel {
    &__title {
        @media screen and (max-width: $breakpoint-mobile) {
            font: {
                size: 16px;
                weight: 18px;
            }
        }
    }
}
.carousel__title {
    @media  (max-width: 1280px) {
        font-size: 1rem;
    }
}
.fixed {
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #fbfafa !important;
}

.margin-top {
    margin-top: calc(v-bind('addresHeight') + 160px + env(safe-area-inset-bottom));
}

.address-card { 
    width: calc(100% - 32px);
    height: 190px;
    top: 90px;
    left: 16px;
    position: fixed;
    z-index: 9999;
}

</style>
