<template lang="pug">
q-scroll-area.full-width.banner(
  :thumb-style="{display: 'none'}")
  .row.no-wrap
    .banner-slide(
      v-for="(slide, index) in slides"
      :key="index"
      style="width:260px ; max-width: 260px"
      )
      img(
          :src="`interface/about/${slide.icon_top}.png`"
        )
      .column
          .font-14.text-primary.text-weight-medium.title-top(:class="{ 'mw-1' : (index===2) }")
            | {{ slide.title_top }}
          div
              span.font-13.q-mt-md.block(style="max-width: 250px")
                | {{ slide.subtitle_top }}
              a(href="tel:+78002005400" v-if="index === 2" rel="nofollow").text-primary.hover-color-positive.font-13.q-mr-xs.link.white-space-nowrap
                |  8-800-200-5-400
              span.font-13.q-mt-md(v-if="index === 2")
                | (С 8:00 по 22:00)
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { copyToClipboard } from 'quasar';

import BaseBtn from '../Base/BaseBtn.vue';

export default {
    name: 'CarouselAdvantages',
    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
    components: {
        BaseBtn,
    },
    setup(props) {
        const count = ref(0);
        const showAdvModal = ref(null);
        const activeAdv = ref(null);
        const categories = ref(null);
        return {
            count,
            categories,
            showAdvModal,
            activeAdv,
        };
    },
};
</script>

<style lang="scss" scoped>
.banner-img {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
}

.banner-slide {
  border-radius: 16px;

  &:not(:last-child) {}
    margin-right: 24px;
  }
.banner {
  height: 280px;
}

.badge {
  position: absolute;
  bottom: 60%;
  width: 54px;
  height: 32px;
  background: $negative;
  border-radius: 14px 0 0 14px;
  right: 0;
  padding: 0;
}

.adv-badge-wrap {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0;
  background: none;
}

.adv-badge {
  border-radius: 10px;
  background: $secondary;
  padding: 2px 4px;
}

.bottom-popup-mobile {
  border-radius: $mobile-border-radius $mobile-border-radius 0 0 !important;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
}
.title-top{
  max-width: 234px;
}
.mw-1{
  max-width: 155px;
}
</style>
